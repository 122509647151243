import axios from "axios";
const url = `${process.env.FORM_INTERMEDIACION_API}comfama/form`;

const tokenizer = process.env.FORM_TOKENIZER_API

const getValidateAccess = async (doc: string) => {
    const data = await axios.get(`${url}/api/v1/validate-access`, {
        params: {
            document: doc
        }
    });

    return data.data.data.status
}

const postUser = async (payload, token) => {
    const data = await axios.post(`${url}/api/v1/save`, payload,
        {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }
    )
    return data
}

const getUser = async (doc, docType, token) => {
    const data = await axios.get(`${url}/api/v1/get`, {
        params: {
            document: doc,
            documentType: docType,
            formId: 1
        },
        headers: {
            Authorization: 'Bearer ' + token
        }
    })

    return data
}

const getUserPre = async (doc, docType, formId) => {
    const data = await axios.get(`${url}/api/v1/get`, {
        params: {
            document: doc,
            documentType: docType,
            formId: formId
        }
    })

    return data
}

const getToken = async (uid) => {
    const data = await axios.get(`${tokenizer}comfama/tokenizer/api/v1/accessToken`,
        {
            params: { 
                uid: uid
            }
        }
    )
    return data
}

export { postUser, getUser, getToken, getUserPre, getValidateAccess }
