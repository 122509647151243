import React, { useState, useEffect, useContext } from "react";
import InputForm from "../../atoms/input-form/inputForm";
import InputFiles from "../../atoms/input-files/inputFiles";
import Button from "../../atoms/button/button";
import Loading from "../../atoms/loading/loading";
import useBrowserMode from '../../../shared/hooks/useBrowserMode'
import "./formEmpleo.scss";
import { postUser, getUser, getToken } from '../../../services/form-intermediacion'
import Modal from "../../atoms/modal/modal";
import GlobalContext from '../../../contexts/globalContext';
import FormWizard from '../../molecules/form-wizard/form-wizard';


const FormEmpleo = (props: any) => {
   const arrForm = props;
   const defaultFormValues = {
      personName: "",
      idNumType: "",
      idNum: "",
      gender: "",
      birthDate: "",
      email: "",
      cellphone: "",
      phone: "",
      interest: "",
      state: "Antioquia",
      optinalState: "",
      city: "",
      studiesLevel: "",
      area: "",
      disability: "",
      disabilityType: "",
      laboralExp: "",
      ocupation: "",
      experienceTime: "",
      secondLaboralExp: "",
      secondOcupation: "",
      secondExperienceTime: "",
      course: "",
      optionalCourse: "",
      techCourses: "",
      englishLevel: "",
      optionalLanguage: "",
      secondLanguageName: "",
      license: "",
      transport: "",
      attachments: [],
      binaries: []
   }

   const titles = {
      gender: "",
      birthDate: "",
      cellphone: "",
      phone: "",
      interest: "",
      state: "",
      optinalState: "",
      city: "",
      studiesLevel: "",
      area: "",
      disability: "",
      disabilityType: "",
      laboralExp: "",
      ocupation: "",
      experienceTime: "",
      secondLaboralExp: "",
      secondOcupation: "",
      secondExperienceTime: "",
      course: "",
      optionalCourse: "",
      techCourses: "",
      englishLevel: "",
      optionalLanguage: "",
      secondLanguageName: "",
      license: "",
      transport: "",
   }

   const [fileCount, setFileCount] = useState(1)
   const [titleValues, setTitleValues] = useState(titles);
   const { isBrowser, document, window } = useBrowserMode();
   const Context = useContext(GlobalContext);
   const [formValues, setFormValues] = useState(defaultFormValues);
   const [checkBox, setCheckBox] = useState(false)
   const [isService, setisService] = useState(true)
   const [loading, setloading] = useState(false)
   const [codeTypeDoc, setCodeTypeDoc] = useState("");
   const [validEmail, setvalidEmail] = useState(true);
   const [validBirthDate, setValidBirthDate] = useState(true);
   const [errorCatch, seterrorCatch] = useState(false);
   const [wizards, setWizards] = useState<any[]>([]);
   const [activeIdx, setActiveIdx] = useState(0)

   const updateFormValues = (value: any, name?: any) => {
      name && setFormValues({ ...formValues, [name]: value })
   }

   const updateTitleValues = () => {
      for (let i = 0; i < 3; i++) {
         arrForm[i].referenciaFormulario.map((inp) => {
            let name = inp.nombreId
            if (!["attachments", "personName", "idNum", "idNumType", "email"].includes(name)) {
               setTitleValues(state => {
                  let newState = state
                  newState[name] = inp.inputPrincipal
                  return newState
               })
            }
         })
      }
   }

   const getAnswer = (key: string) => {
      if ((key === "disabilityType" && formValues.disability === "No") ||
         ((key === "secondOcupation" || key === "secondExperienceTime") && formValues.secondLaboralExp === "No") ||
         (key === "secondLanguageName" && formValues.optionalLanguage === "No") ||
         (key === "area" && !checkStudiesLevel())) {
         return ""
      }
      return formValues[key]
   }

   const checkStudiesLevel = (): boolean => {
      if (
         ["Primaria (1-5)",
            "Básica secundaria (6-9)",
            "Bachiller",
            "Bachiller en curso"].includes(formValues.studiesLevel)
      ) {
         return false
      }
      return true
   }

   const handleSendMessage = async () => {
      try {
         setloading(true)
         const authSecond = await getToken(Context?.gigyaContext?.gigyaAccount?.UID)
         if (authSecond.data.status !== 200) {
            seterrorCatch(true)
            setisService(false)
            return
         }
         let payloadResponses: any[] = []
         for (let key in titleValues) {
            let response = {
               contentfulId: key,
               questionDescription: titleValues[key],
               answer: getAnswer(key),
               type: requestType(key)
            }
            payloadResponses.push(response)
         }

         let payload = {
            formId: 1,
            user: {
               document: formValues.idNum,
               documentType: codeTypeDoc,
               fullname: formValues.personName,
               email: formValues.email
            },
            responseList: payloadResponses,
            attachmentList: formValues.binaries
         }

         const req = await postUser(payload, authSecond.data.data.accessToken);
         if (req.data.status === 200) {
            setFormValues(defaultFormValues)
            setisService(false)
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ 'event': 'pushEventGA', 'categoria': 'FormularioTrabajoProposito', 'accion': 'ClicFormulario', 'etiqueta': 'EnviarExitoso' });
         } else {
            setisService(false)
            seterrorCatch(true)
         }
         setloading(false)
      } catch (error) {
         setloading(false)
         seterrorCatch(true)
         setisService(false)
         console.log(error)
      }
   }

   const errorDetected = (_input): boolean => {
      if (_input.nombreId === 'email') return !validEmail

      if (_input.nombreId === 'birthDate') return !validBirthDate

      if (_input.nombreId === 'secondLanguageName') {
         return formValues.optionalLanguage === "Sí" && formValues.secondLanguageName === ""
      }

      if (_input.nombreId === 'disabilityType') {
         return formValues.disability === "Sí" && formValues.disabilityType === ""
      }

      if (_input.nombreId === 'optinalState') {
         return formValues.state === "Otro" && formValues.optinalState === ""
      }

      if (_input.nombreId === 'optionalCourse') {
         return formValues.course === "Otros" && formValues.optionalCourse === ""
      }

      if (_input.nombreId === 'secondOcupation') {
         return formValues.secondLaboralExp === "Sí" && formValues.secondOcupation === ""
      }

      if (_input.nombreId === 'secondExperienceTime') {
         return formValues.secondLaboralExp === "Sí" && formValues.secondExperienceTime === ""
      }

      return false
   }

   const requestType = (key: string): string => {
      if (key === "email") return "EMAIL"
      if (key === "birthDate") return "DATE"
      if (key === "cellphone" || key === "phone") return "NUMBER"
      return "TEXT"
   }

   const readFile = async (file) => {
      return new Promise((resolve) => {
         var reader = new FileReader()
         reader.readAsDataURL(file)
         reader.onload = () => {
            var fileType = file.name.match(/(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx)$/i)
            updateFormValues([...formValues.binaries, { fileBase64: reader.result.split(",")[1], key: "HV" + fileCount, type: fileType ? fileType[1].slice(1).toLowerCase() : "otro" }], "binaries")
            setFileCount(fileCount + 1)
            resolve(true)
         }
      })
   }

   const toBase64 = async (files) => {
      for (let file of files) {
         await readFile(file[0])
      }
   };

   const createWizards = () => {
      let newWizards : any[] = []
                     
      for (let i = 0; i < 3; i++) {
         newWizards.push({
            description: arrForm[i].tituloGeneral,
            idx: i,
            active: i === activeIdx
         })
      }
      setWizards([...newWizards])
   }

   const loadUserHistory = async () => {

      const auth = await getToken(Context?.gigyaContext?.gigyaAccount?.UID)
      if (auth.data.status !== 200) {
         seterrorCatch(true)
         setisService(false)
         return
      }
      let previousData: any = {}
      const res = await getUser(formValues.idNum, codeTypeDoc, auth.data.data.accessToken)
      if (res.status === 200 && res.data.status === 200) {
         const userData = res.data.data
         previousData.idNum = userData.user.document
         previousData.personName = userData.user.fullname
         previousData.email = userData.user.email
         setCodeTypeDoc(userData.user.documentType)
         for (let val of userData?.responseList) {
            previousData[val.contentfulId] = val.answer
         }

      }
      setFormValues({ ...formValues, ...previousData })
   }

   const isDisabledForm = () => {
      const MANDATORY = [
         "personName",
         "idNumType",
         "idNum",
         "gender",
         "cellphone",
         "interest",
         "state",
         "city",
         "studiesLevel",
         "disability",
         "laboralExp",
         "ocupation",
         "experienceTime",
         "course",
         "englishLevel",
         "optionalLanguage",
         "license",
         "transport",
      ]
      return (
         !validBirthDate || !validEmail ||
         !Object.entries(formValues).every(([key, value]) => (!MANDATORY.includes(key) || value !== "")) ||
         !checkBox ||
         (formValues.state === "Otro" && formValues.optinalState === "") ||
         (formValues.course === "Otros" && formValues.optionalCourse === "") ||
         (formValues.optionalLanguage === "Sí" && formValues.secondLanguageName === "") ||
         (formValues.disability === "Sí" && formValues.disabilityType === "") ||
         (formValues.secondLaboralExp === "Sí" && (formValues.secondOcupation === "" || formValues.secondExperienceTime === ""))
      )
   }

   const getInput = (_input, _i, _length) => {

      switch (_input.tipoCampo) {
         case "file":
            return (
               <div className="input__file__container">
                  <h6 className="input__file__container__h6">{_input.inputPrincipal}</h6>
                  <InputFiles
                     key={`inp${_i}`}
                     title={_input.inputPrincipal}
                     nameId={_input.nombreId}
                     value={formValues[_input.nombreId]}
                     handleChange={(e) => updateFormValues(e, _input.nombreId)}
                     maxFiles={2}
                     binaries={formValues.binaries}
                     validTypes={["png", "jpg", "jpeg", "pdf", "doc"]}
                  />
               </div>
            )
         default:
            return (
               <div className={_i === 0 && _length > 2 && _length % 2 !== 0 ? `input__container__first` : `input__container`}
                  key={_i}
                  id={`container_${_input.nombreId}`}>
                  <h6 className="input__container__h6">{_input.inputPrincipal}</h6>
                  <InputForm
                     key={`inp${_i}`}
                     title={_input.inputPrincipal}
                     type={_input.tipoCampo}
                     nameId={_input.nombreId}
                     value={formValues[_input.nombreId]}
                     handleChange={(e) => updateFormValues(e, _input.nombreId)}
                     isError={errorDetected(_input)}
                     errorMessage={_input.mensajeError}
                     options={_input.listaSelect || null}
                     setCodeTypeDoc={setCodeTypeDoc}
                     gigyaAccount={Context.gigyaContext.gigyaAccount}
                     placeholder={_input.textoTerminos ? _input.textoTerminos.json.content[0].content[0].value : ""}
                  />
               </div>
            )
      }
   }

   useEffect(() => {
      if (isBrowser && Context.gigyaContext.gigyaAccount) {
         props.setIsUser(true)
         var typeId: string = ""
         var userBirthDate: string = ""
         var userGender: string = ""
         if (Context.gigyaContext.gigyaAccount.profile.birthDay && Context.gigyaContext.gigyaAccount.profile.birthMonth && Context.gigyaContext.gigyaAccount.profile.birthYear) {
            userBirthDate = Context.gigyaContext.gigyaAccount.profile.birthYear + "-" +
               (+Context.gigyaContext.gigyaAccount.profile.birthMonth < 10 ? "0" + Context.gigyaContext.gigyaAccount.profile.birthMonth : Context.gigyaContext.gigyaAccount.profile.birthMonth) + "-" +
               (+Context.gigyaContext.gigyaAccount.profile.birthDay < 10 ? "0" + Context.gigyaContext.gigyaAccount.profile.birthDay : Context.gigyaContext.gigyaAccount.profile.birthDay)
         }
         if (Context.gigyaContext.gigyaAccount.data.docType === "CO1C") { typeId = "Cedula ciudadanía"; setCodeTypeDoc("02") }
         if (Context.gigyaContext.gigyaAccount.data.docType === "CO1T") { typeId = "Tarjeta de identidad"; setCodeTypeDoc("04") }
         if (Context.gigyaContext.gigyaAccount.data.docType === "CO1L") { typeId = "Registro civil"; setCodeTypeDoc("11") }
         if (Context.gigyaContext.gigyaAccount.data.docType === "CO1E") { typeId = "Cedula de extranjería"; setCodeTypeDoc("03") }
         if (Context.gigyaContext.gigyaAccount.data.docType === "CO1P") { typeId = "Pasaporte"; setCodeTypeDoc("05") }
         if (Context.gigyaContext.gigyaAccount.data.docType === "CO1V") { typeId = "Permiso especial de permanencia"; setCodeTypeDoc("15") }
         if (Context.gigyaContext.gigyaAccount.profile.gender === "m") { userGender = "Masculino" }
         if (Context.gigyaContext.gigyaAccount.profile.gender === "f") { userGender = "Femenino" }
         if (document.getElementById("select_idNumType")) {
            document.getElementById("select_idNumType").innerHTML = `<option className="a-input a-input-select" key={0}>${typeId}</option>`;
         }
         if (document.getElementById("select_idNumType")) {
            document.getElementById("select_idNumType").disabled = true
         }
         if (document.getElementById("select_gender")) {
            document.getElementById("select_gender").disabled = true
         }
         setFormValues({
            ...formValues,
            personName: Context.gigyaContext.gigyaAccount.profile.firstName + " " + Context.gigyaContext.gigyaAccount.profile.lastName,
            email: Context.gigyaContext.gigyaAccount.profile.email,
            idNum: Context.gigyaContext.gigyaAccount.data.docNumber, idNumType: typeId, phone: Context.gigyaContext.gigyaAccount.data.phone, cellphone: Context.gigyaContext.gigyaAccount.data.mobile,
            gender: userGender, birthDate: userBirthDate
         })
      } else if (formValues.idNum) {
         window.location.href = "/encuentra-trabajo-con-proposito"
      }
   }, [Context.gigyaContext.gigyaAccount, activeIdx])

   useEffect(() => {
      setFormValues({ ...formValues, binaries: [] })
      if (formValues.attachments) {
         toBase64(formValues.attachments)
      }
   }, [formValues.attachments])

   useEffect(() => {
      if (formValues.email) {
         setvalidEmail(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formValues.email))
      }
   }, [formValues.email])

   useEffect(() => {
      if (formValues.birthDate) {
         let bd = new Date(formValues.birthDate)
         let nw = new Date()
         let dateDiff = nw.getUTCDate() - bd.getUTCDate()
         let monthDiff = nw.getUTCMonth() - bd.getUTCMonth()
         let yearDiff = nw.getUTCFullYear() - bd.getUTCFullYear()

         if (yearDiff > 18 ||
            (yearDiff == 18 &&
               (monthDiff > 0 || (monthDiff == 0 && dateDiff > 0))
            )) {
            setValidBirthDate(true)
         } else {
            setValidBirthDate(false)
         }
      }
   }, [formValues.birthDate])

   useEffect(() => {
      document.getElementById("container_optionalCourse") &&
         (formValues.course === "Otros y/o varios de los mencionados" ?
            document.getElementById("container_optionalCourse").style.display = ""
            :
            document.getElementById("container_optionalCourse").style.display = "none"
         )
      document.getElementById("container_optinalState") &&
         (formValues.state === "Otro" ?
            document.getElementById("container_optinalState").style.display = ""
            :
            document.getElementById("container_optinalState").style.display = "none"
         )
      document.getElementById("container_disabilityType") &&
         (formValues.disability === "Sí" ?
            document.getElementById("container_disabilityType").style.display = ""
            :
            document.getElementById("container_disabilityType").style.display = "none"
         )
      document.getElementById("container_secondOcupation") &&
         document.getElementById("container_secondExperienceTime") &&
         (formValues.secondLaboralExp === "Sí" ?
            (document.getElementById("container_secondOcupation").style.display = "",
               document.getElementById("container_secondExperienceTime").style.display = "")
            :
            (document.getElementById("container_secondOcupation").style.display = "none",
               document.getElementById("container_secondExperienceTime").style.display = "none")
         )
      document.getElementById("container_secondLanguageName") &&
         (formValues.optionalLanguage === "Sí" ?
            document.getElementById("container_secondLanguageName").style.display = ""
            :
            document.getElementById("container_secondLanguageName").style.display = "none"
         )

      document.getElementById("container_area") &&
         (checkStudiesLevel() ?
            document.getElementById("container_area").style.display = ""
            :
            document.getElementById("container_area").style.display = "none"
         )

   }, [activeIdx, formValues.course, formValues.state, formValues.disability, formValues.secondLaboralExp, formValues.optionalLanguage, formValues.studiesLevel])

   useEffect(() => {
      updateTitleValues()
      if (formValues.idNum) {
         loadUserHistory()
      }
   }, [formValues.idNum])

   useEffect(() => {
      if (props.isUser == false) {
         const timerId = setTimeout(() => {
            if (props.isUser == false) {
               Context.gigyaContext.login()
            }
         }, 1500)
         return () => clearTimeout(timerId)
      }
   }, [formValues])

   useEffect(() => {
      createWizards()
   }, [activeIdx])


   return (
      <>
         { isService ? (
            <div className="o-form-empleo">
               <span className="o-form-empleo__person-title">Autorización datos personales</span>
               <div className="o-form-empleo__group--open"  >
                  Comfama cuida tu información. Haz clic aquí para autorizar el tratamiento de tus datos personales.
               </div>
               <div className="o-form-empleo__bottom-check">
                  <input type="checkbox" id="cbox2" value="second_checkbox" onChange={(e) => setCheckBox(e.target.checked)} />
                  <label className="gtmFormularioTrabajoPropositoFormularioTratamientoDatos" htmlFor="cbox2">Autorización de <a className="gtmFormularioTrabajoPropositoFormularioTratamientoDatos" href="https://serviciosenlinea.comfama.com/webinicio/tratamientodatos.htm" target="_blank" rel="noreferrer">tratamiento de datos.</a></label>
               </div>
               <FormWizard wizards={wizards} title="Realiza tu inscripción en tres pasos"/>
               { activeIdx === 0 && <div id="groupIntermediacion0" className="o-form-empleo__group--open">
                  <span className="o-form-empleo__person-title"> {arrForm[0].tituloGeneral || "Datos Personales"} </span>
                  <div className="o-form-empleo__group-cont">
                     {arrForm && arrForm[0].referenciaFormulario.map((inp, i) => (
                        getInput(inp, i, arrForm[0].referenciaFormulario.length)
                     ))}
                  </div>
               </div>}
               { activeIdx === 1 && <div id="groupIntermediacion1" className="o-form-empleo__group--open">
                  <span className="o-form-empleo__person-title"> {arrForm[1].tituloGeneral || "Experiencia laboral"} </span>
                  <div className="o-form-empleo__group-cont">
                     {arrForm && arrForm[1].referenciaFormulario.map((inp, i) => (
                        getInput(inp, i, arrForm[1].referenciaFormulario.length)
                     ))}
                  </div>
               </div>}
               {activeIdx === 2 && <div id="groupIntermediacion3" className="o-form-empleo__group--open">
                  <span className="o-form-empleo__person-title"> {arrForm[2].tituloGeneral || "Otros"} </span>
                  <div className="o-form-empleo__group-cont">
                     {arrForm && arrForm[2].referenciaFormulario.map((inp, i) => (
                        inp.tipoCampo.includes("file") ? (
                           <InputFiles
                              key={i}
                              title={inp.inputPrincipal}
                              nameId={inp.nombreId}
                              value={formValues[inp.nombreId]}
                              handleChange={updateFormValues}
                              maxFiles={2}
                              binaries={formValues.binaries}
                              validTypes={["png", "jpg", "jpeg", "pdf", "doc"]}
                           />
                        ) : (
                           getInput(inp, i, arrForm[2].referenciaFormulario.length + 1)
                        )
                     ))}
                  </div>
               </div>}
               {<div className="o-form-empleo__bottom">
                  <div className="o-form-empleo__bottom-btns">
                     <div className="o-form-empleo__bottom-btn">
                        {activeIdx > 0 && <Button
                           id={"btn-enviar"}
                           type={"button"}
                           classname={`primary `}
                           text={"Volver" || "Send"}
                           isDisabled={false}
                           gtmMark={"gtmFormularioTrabajoPropositoFormularioVolver"}
                           onClickAction={() => setActiveIdx(activeIdx - 1)}
                        />}
                        {loading ? <Loading /> :
                           activeIdx === 2 && <Button
                              id={"btn-enviar"}
                              type={"button"}
                              classname={`secondary `}
                              text={arrForm[3].referenciaFormulario[0].tituloBotonTerminos || "Send"}
                              isDisabled={isDisabledForm()}
                              gtmMark={"gtmFormularioTrabajoPropositoFormularioEnviar-3"}
                              onClickAction={() => handleSendMessage()}
                           />
                        }
                        {activeIdx < 2 && <Button
                           id={"btn-enviar"}
                           type={"button"}
                           classname={`secondary `}
                           text={"Continuar" || "Send"}
                           isDisabled={false}
                           gtmMark={"gtmFormularioTrabajoPropositoFormularioEnviar-1"}
                           onClickAction={() => setActiveIdx(activeIdx + 1)}
                        />}
                     </div>
                  </div>
               </div>}
            </div>
         ) : (errorCatch && errorCatch === true ? (
            <Modal open={true}>
               <div className="o-form-empleo-success">
                  <div className="o-form-empleo-success__content">
                     <i className="icon__check icon-close" />
                     <h3 className="o-form-empleo-success__content__message">Error al enviar el mensaje</h3>
                     <p className="o-form-empleo-success__content__info">En este momento no podemos procesar su solicitud, por favor intentelo mas tarde.</p>
                     <a href="" className="o-form-empleo-success__content__btn" >ACEPTAR</a>
                  </div>
               </div>
            </Modal>
         ) : (
            <Modal open={true}>
               <div className="o-form-empleo-success">
                  <div className="o-form-empleo-success__content">
                     <i className="icon__check icon-check-circle1" />
                     <h3 className="o-form-empleo-success__content__message">¡Tu mensaje ha sido enviado con éxito!</h3>
                     <p className="o-form-empleo-success__content__info">
                        ¡Muchas gracias! Ya tenemos tus datos.  Si quieres ver algunas de las vacantes disponibles esta semana explora nuestro sitio o entra <a href="https://www.comfama.com/trabajo-con-proposito/empleo/vacantes-servicio-publico-de-empleo/">aquí</a>.  Recuerda que también te acompañamos en el descubrimiento de tu propósito y tu proceso de desarrollo para lograrlo ¡Conoce más de nuestro programa de <a href="https://www.comfama.com/trabajo-con-proposito/empleo/mentoria-para-el-desempleo-antioquia/">Mentoría</a>!
                        <br />
                        <br />
                        Trabajar con propósito es conectar los propósitos personales con los laborales, y continuamente responder preguntas como: ¿para qué soy bueno?, ¿qué me gusta hacer?, ¿qué causas me mueven?, ¿en qué creo?, ¿qué me disgusta? Hoy comienzas un nuevo proceso y juntos construiremos el camino de las oportunidades.
                     </p>
                     <a href="/" className="o-form-empleo-success__content__btn" >ACEPTAR</a>
                  </div>
               </div>
            </Modal>
         )
         )}
      </>
   );
};

export default FormEmpleo;
