import React from "react";
import Layout from "../components/organisms/layout/layout";
import SEO from "../shared/seo";
import BodyForm from "../components/organisms/body-form-empleo/bodyForm";
import { useStaticQuery, graphql } from "gatsby";

const FormEmpleoPage = () => {
   const data = useStaticQuery(graphql`
      query contentfulFormEmpleo {  
         allContentfulContactenos(filter: { titulo: { eq: "Información Contáctanos Empleo" } }) {
            nodes {
               tituloPrincipal
               titulosFormulario {
                  tituloGeneral
                  referenciaFormulario {
                     tipoCampo
                     listaSelect
                     mensajeError
                     inputPrincipal
                     nombreId
                     tituloBotonTerminos
                     textoTerminos {
                        json
                     }
                  }
               }
               imagenesEmpleo {
                  file {
                     url
                  }
                  title
                  fluid(maxWidth: 960, quality: 75) {
                     src
                     srcSet
                     base64
                     aspectRatio
                     sizes
                  }
               }
            }
         }
      }
   `)
   
   return (
      <Layout hideHeaderDesktop={true} hideHeaderMobile={true} isNotRoot={true}>
         <SEO title="Formulario " />
         <BodyForm {...data.allContentfulContactenos.nodes[0]} />
      </Layout>
   );
};
export default FormEmpleoPage;
