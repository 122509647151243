import React, { useState } from "react";
import Breadcrumb from "../../atoms/breadcrumb/breadcrumb";
import FormEmpleo from "../form-empleo/formEmpleo";
import "./bodyForm.scss";
import Modal from "../../atoms/modal/modal";

interface IBodyForm {
   tituloPrincipal: string;
   titulosFormulario: Array<any>;
   imagenesEmpleo: any;
}

const BodyForm = (props: IBodyForm) => {
   const [ModalRequirements, setModalRequirements] = useState(false);
   const [isUser, setIsUser] = useState(false);

   return (
      <div className="o-bodyForm">
         <Breadcrumb content={`Inicio / Encuentra trabajo con propósito`} />

         <div className={`o-bodyForm__header`}>
            <h1 className="o-bodyForm__header-title title-primary">
               {props.tituloPrincipal}
            </h1>
            <p className="o-bodyForm__header-subtitle">
               Si estás en búsqueda de un empleo que se conecte con tu
               propósito, déjanos tus datos y cuando tengamos una vacante que se
               ajuste a tu perfil, nos pondremos en contacto contigo.{" "}
               <b>Verifica que tus datos personales estén correctos.</b>
               {/*Si estás en búsqueda de un empleo que se conecte con tu propósito, déjanos tus datos para verificar el cumplimiento de <a href="#" className="gtmFormularioTrabajoPropositoFormularioRequisitos" onClick={() => setModalRequirements(true)}>requisitos</a> y cuando tengamos una vacante que se ajuste a tu perfil, nos pondremos en contacto contigo. Verifica que tus datos personales estén correctos.*/}
            </p>
            <p className="o-bodyForm__header-message">
               <span>
                  Recuerda que los campos señalados con el signo <b>(*)</b> son
                  obligatorios.
               </span>
            </p>
         </div>

         <div className={`o-bodyForm__cont`}>
            <div className="o-bodyForm__cont-form">
               <FormEmpleo
                  {...props.titulosFormulario}
                  isUser={isUser}
                  setIsUser={setIsUser}
               />
            </div>
            {/*<div className="o-bodyForm__cont-img hide-on-mobile">
               {
                  props.imagenesEmpleo.map((img, i) => {
                     return (
                        <div key={i}>
                           <img loading="lazy" src={img && img.file.url} alt={img.title}/>
                        </div>
                     )
                  })
               }
            </div>*/}
         </div>
         {
            <Modal open={ModalRequirements} onClose={() => {}}>
               <div className="o-form-empleo-requirements">
                  <div className="o-form-empleo-requirements__content">
                     <div className="o-form-empleo-requirements__content__icon">
                        <i
                           className="icon__check icon-close"
                           onClick={() => setModalRequirements(false)}
                        />
                     </div>
                     <h3 className="o-form-empleo-requirements__content__message">
                        ¿Cuáles son los requisitos para acceder al servicio?{" "}
                     </h3>
                     <p className="o-form-empleo-requirements__content__info">
                        La intermediación laboral de Empleo con Propósito está
                        dirigida a las personas que perdieron su empleo y que
                        cumplen requisitos de haber realizado aportes por un año
                        continuo o discontinuo en los últimos tres años en caso
                        de personas dependientes, y dos años en los últimos tres
                        años en el caso de las personas independientes.
                     </p>
                  </div>
               </div>
            </Modal>
         }
      </div>
   );
};

export default BodyForm;
